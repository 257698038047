<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Tabela de descontos</h4>
        </div>
        <div class="d-flex justify-content-end mb-2">
          <b-button variant="primary" @click="openCreateDiscountModal">
            ADICIONAR NOVO DESCONTO
          </b-button>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            :items="discounTable"
            :fields="discountField"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                title="Editar descrição"
                @click="openEditDescriptionModal(row.item)"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>

              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                title="Adicionar novos valores"
                @click="openAddValueDiscountModal(row.item)"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                title="Desativar desconto"
                @click="openActiveTableModal(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-card-text>

        <b-modal
          v-model="createDiscountModal"
          title="Insira a descrição do desconto"
          hide-footer
        >
          <b-form @submit.prevent="createTable">
            <form-input
              v-model="discountDescription"
              label="Descrição"
              placeholder="Informe a descrição da tabela de desconto"
              icon="pen"
              required
              :errors="errors['description']"
            />
            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit"> CRIAR </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="editDescriptionModal"
          title="Editar descrição da tabela"
          hide-footer
        >
          <b-form @submit.prevent="updateDescription">
            <form-input
              v-model="discountDescription"
              label="Descrição"
              placeholder="Informe a descrição da tabela de desconto"
              icon="pen"
              :errors="errors['description']"
            />
            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit"> SALVAR </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="deactiveTableModal"
          hide-footer
          title="Desativar tabela"
        >
          <p>
            Deseja desativar a tabela
            {{ discountDescription }}?
          </p>

          <div class="d-flex justify-content-end">
            <b-button variant="danger" @click="activateDeactivateTable"
              >DESATIVAR</b-button
            >
          </div>
        </b-modal>

        <b-modal
          v-model="addValueDiscountModal"
          size="xl"
          title="Insira os valores"
          hide-footer
        >
          <b-form @submit.prevent="addValueToDiscount">
            <b-row>
              <b-col>
                <form-number
                  v-model="valueDiscountInfo.baseValue"
                  label="Valor Base R$"
                  icon="currency-dollar"
                  required
                  :errors="errors['baseValue']"
                />
              </b-col>
              <b-col>
                <form-number
                  v-model="valueDiscountInfo.discount"
                  label="Desconto %"
                  icon="percent"
                  required
                  :errors="errors['discount']"
                />
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit" class="mb-2">
                ADICIONAR
              </b-button>
            </div>

            <b-table :items="valueDiscountTable" :fields="valueFields">
              <template #cell(baseValue)="row">
                {{ row.item.edit ? "" : formatCash(row.item.baseValue) }}
                <form-number
                  v-model="row.item.baseValue"
                  v-if="row.item.edit"
                  placeholder="Valor Base R$"
                  icon="currency-dollar"
                  required
                  :errors="errorsTable['baseValue']"
                />
              </template>
              <template #cell(discount)="row">
                {{ row.item.edit ? "" : `${row.item.discount}%` }}
                <form-number
                  v-model="row.item.discount"
                  v-if="row.item.edit"
                  placeholder="Desconto %"
                  icon="percent"
                  required
                  :errors="errorsTable['discount']"
                />
              </template>
              <template #cell(actions)="row">
                <b-button size="sm" class="mr-1" @click="deleteValue(row.item)">
                  <b-icon icon=" trash"></b-icon>
                </b-button>
                <b-button
                  v-if="!row.item.edit"
                  size="sm"
                  variant="primary"
                  @click="editValueFunction(row.item)"
                >
                  <b-icon icon="pencil" />
                </b-button>

                <b-button
                  v-if="row.item.edit"
                  size="sm"
                  variant="success"
                  @click="updateValue(row.item)"
                >
                  <b-icon
                    :icon="spin ? 'hourglass-split' : 'check'"
                    :animation="spin ? 'spin' : ''"
                  />
                </b-button>
              </template>
            </b-table>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import FormNumber from "../../components/form/FormNumber.vue";
import { mapMutations } from "vuex";
import { formatMoney } from "../../utils/formatMoney";
export default {
  components: { FormInput, FormNumber },
  data() {
    return {
      discounTable: [],
      spin: false,
      createDiscountModal: false,
      deactiveTableModal: false,
      discountDescription: "",
      discountId: null,
      addValueDiscountModal: false,
      editDescriptionModal: false,
      valueDiscountTable: [],
      errors: {},
      errorsTable: {},
      valueDiscountInfo: {
        discountTableId: null,
        baseValue: null,
        discount: null,
      },
      valueFields: [
        {
          key: "baseValue",
          label: "Valor Base",
        },
        {
          key: "discount",
          label: "Desconto",
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],

      discountField: [
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "150px !important" },
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError", "setOverlay"]),

    formatCash(cash) {
      return formatMoney(cash);
    },

    openCreateDiscountModal() {
      this.errors = {};
      this.discountDescription = "";
      this.createDiscountModal = true;
    },

    openActiveTableModal(item) {
      this.deactiveTableModal = true;
      this.discountDescription = item.description;
      this.discountId = item.id;
    },

    editValueFunction(item) {
      const index = this.valueDiscountTable.findIndex(
        (element) => element.id === item.id
      );

      this.valueDiscountTable.splice(index, 1, { ...item, edit: true });
    },

    openEditDescriptionModal(item) {
      this.errors = {};
      this.discountId = item.id;
      this.discountDescription = item.description;
      this.editDescriptionModal = true;
    },

    async updateValue(item) {
      this.spin = true;
      this.errorsTable = {};
      try {
        const { data } = await http.put(`discount/${item.id}/updateValue`, {
          baseValue: item.baseValue,
          discount: item.discount,
        });

        const index = this.valueDiscountTable.findIndex(
          (element) => element.id === item.id
        );

        this.valueDiscountTable.splice(index, 1, data);
        const newArray = this.valueDiscountTable.sort((a, b) => {
          if (Number(a.baseValue) < Number(b.baseValue)) {
            return 1;
          }
          if (Number(a.baseValue) > Number(b.baseValue)) {
            return -1;
          }

          return 0;
        });
        this.valueDiscountTable = newArray;
      } catch (err) {
        if (err.response.status === 406) {
          this.errorsTable = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
      this.spin = false;
    },

    async addValueToDiscount() {
      this.errors = {};
      try {
        const { data } = await http.post(
          `discount/value`,
          this.valueDiscountInfo
        );
        this.valueDiscountTable.push(data);
        const newArray = this.valueDiscountTable.sort((a, b) => {
          if (Number(a.baseValue) > Number(b.baseValue)) {
            return 1;
          }
          if (Number(a.baseValue) < Number(b.baseValue)) {
            return -1;
          }

          return 0;
        });
        this.valueDiscountTable = newArray;
        this.valueDiscountInfo.baseValue = "";
        this.valueDiscountInfo.discount = "";
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async openAddValueDiscountModal(item) {
      const { data } = await http(`discount/${item.id}/values`);
      this.valueDiscountTable = data;

      this.valueDiscountInfo.baseValue = "";
      this.valueDiscountInfo.discount = "";
      this.valueDiscountInfo.discountTableId = item.id;
      this.errors = {};
      this.addValueDiscountModal = true;
    },

    async createTable() {
      this.errors = {};
      try {
        const { data } = await http.post(`discount`, {
          description: this.discountDescription,
        });
        this.discounTable.push(data);
        this.createDiscountModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },
    async updateDescription() {
      this.errors = {};
      try {
        const { data } = await http.put(`discount/${this.discountId}`, {
          description: this.discountDescription,
        });

        const index = this.discounTable.findIndex(
          (discount) => discount.id === data.id
        );
        this.discounTable.splice(index, 1, data);
        this.editDescriptionModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async activateDeactivateTable() {
      this.deactiveTableModal = false;
      this.setOverlay(true);
      this.deactiveTableModal = false;
      const { data } = await http.put(
        `discount/${this.discountId}/deactivate`,
        {
          active: false,
        }
      );

      const newList = this.discounTable.filter((table) => table.id !== data.id);
      this.discounTable = newList;
      this.setOverlay(false);
    },

    async deleteValue(item) {
      await http.delete(`discount/${item.id}/value`);
      const newTable = this.valueDiscountTable.filter(
        (value) => value.id !== item.id
      );
      this.valueDiscountTable = newTable;
    },
  },
  async created() {
    this.setOverlay(true);
    const { data } = await http(`discount`);
    this.discounTable = data;
    this.setOverlay(false);
  },
};
</script>
